@import "~antd/dist/antd.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(242, 246, 255);
}

#root {
  padding: 16px;
}

.filename {
  text-align: center;
}

.header {
  text-align: center;
}

.input {
  width: 320px;
  max-width: 100%;
  margin: 0 auto;
}

.pagination {
  margin-top: 16px;
  text-align: center;
}

.main {
  margin-top: 16px;
  text-align: center;
}

.stage {
  margin-top: 16px;
  width: 800;
  height: 600;
  text-align: center;
}
